import { DocumentSnapshot, getDocs, loadBundle, namedQuery, Timestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { ClubIdentifier } from '../domain/types';

const _convertDocToEventDetails = (
	snapshot: DocumentSnapshot,
	clubId: ClubIdentifier
): EventData => ({
	id: snapshot.id,
	name: snapshot.get('title'),
	description: snapshot.get('description'),
	startTimeMs: (snapshot.get('start') as Timestamp).toMillis(),
	imageUri: snapshot.get('imageUrl'),
	tags: snapshot.get('tags'),
	shareLink: snapshot.get('shareLink'),
	shareLinks: snapshot.get('shareLinks'),
	showOnHomePage: snapshot.get('showOnHomePage') ?? false,
	locationName: snapshot.get('locationName'),
	maxAge: snapshot.get('maxAge'),
	minAge: snapshot.get('minAge'),
	clubId: snapshot.get('clubId') ?? [clubId],
});

export type EventData = {
	id: string;
	name: string;
	description: string;
	startTimeMs: number;
	imageUri: string;
	tags: string[];
	shareLink?: string;
	clubId: ClubIdentifier[];
	shareLinks?: Partial<Record<ClubIdentifier, string>>;
	showOnHomePage?: boolean;
	locationName?: string;
	maxAge?: number;
	minAge?: number;
};

export const getEvents = async (clubId: ClubIdentifier) => {
	try {
		const url = `${process.env.REACT_APP_CONTENT_HOST}/content/loxclubevents?clubId=${clubId}`;
		const response = await fetch(url);
		const bundle = await response.text();

		await loadBundle(firestore, bundle);

		const query = await namedQuery(firestore, 'active-loxclubevents-query');
		if (query) {
			const snapshot = await getDocs(query);
			return snapshot.docs
				.map((doc) => _convertDocToEventDetails(doc, clubId))
				.filter((event) => event.clubId.includes(clubId));
		}
	} catch (e) {
		console.log('failed to load events query', e);
	}
	console.warn('events query not found');
	return [];
};

export const getEvent = async (
	clubId: ClubIdentifier,
	eventId: string
): Promise<EventData | undefined> => {
	const events = await getEvents(clubId);
	return events.find((event) => event.id === eventId);
};
