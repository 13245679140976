import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import Layout from './components/Layout';
import EventRoute from './routes/event';
import { theme } from './themes/ThemeSelector';
import { Theme } from './domain/types';
import Home from './routes/home';

const _getFavicon = () => {
	switch (theme) {
		case Theme.LOX_CLUB: {
			return '/images/loxclub_favicon.ico';
		}
		case Theme.JADE_CLUB: {
			return '/images/JadeClubFavicon.ico';
		}
	}
};

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
		errorElement: <ErrorPage />,
	},
	{
		path: 'events/:eventId',
		element: <EventRoute />,
		errorElement: <ErrorPage />,
	},
]);

function App() {
	return (
		<HelmetProvider>
			<Helmet>
				<link rel="icon" href={_getFavicon()} />
			</Helmet>
			<Layout>
				<RouterProvider router={router} />
			</Layout>
		</HelmetProvider>
	);
}

export default App;
