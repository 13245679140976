import { useEffect, useState } from 'react';
import { EventComponentParams, EventDetails } from './types';
import { getEvent } from '../../api/events';
import { useTranslation } from 'react-i18next';
import { ClubIdentifier } from '../../domain/types';
import { useNavigate } from 'react-router-dom';

const useEventViewModel = ({ eventId }: { eventId: string }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [eventData, setEventData] = useState<EventDetails>();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		(async () => {
			setLoading(true);
			const event = await getEvent(t('club_id'), eventId);
			if (event) {
				setEventData({
					id: event.id,
					name: event.name,
					description: event.description,
					startTimeMs: event.startTimeMs,
					imageUri: event.imageUri,
					tags: event.tags,
					shareLink: event?.shareLinks?.[t('club_id') as ClubIdentifier],
				});
			}
			setLoading(false);
		})();
	}, [eventId, t]);

	const params: EventComponentParams = {
		eventData,
		loading,
		goBack: () => navigate('/'),
	};

	return params;
};

export default useEventViewModel;
