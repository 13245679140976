import { useEffect, useState } from 'react';
import { EventData, getEvents } from '../../api/events';
import { useTranslation } from 'react-i18next';
import { ClubIdentifier } from '../../domain/types';

export interface Event {
	id: string;
	location: string;
	date: string;
	title: string;
	description: string;
	ages: string;
}

export const useEventData = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [events, setEvents] = useState<EventData[]>([]);
	useEffect(() => {
		async function getAllEvents() {
			setLoading(true);
			const events = await getEvents(t('club_id') as ClubIdentifier);
			setEvents(events.filter((event) => event.startTimeMs > Date.now() && event.showOnHomePage));
			setLoading(false);
		}
		getAllEvents();
	}, [t]);
	return {
		loading,
		events,
	};
};
