import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import dateformat from 'dateformat';
import useEventViewModel from './model';

const TagPill: FC<{ tag: string }> = ({ tag }) => <div className="eventTagPill">{tag}</div>;

function BackButton({ goBack }: { goBack: () => void }) {
	return (
		<Row>
			<Col>
				<button
					className="btn btn-link text-decoration-none"
					onClick={goBack}
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '0.5rem',
						fontSize: '1rem',
						color: '#fff',
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						className="bi bi-chevron-left"
						viewBox="0 0 16 16"
					>
						<path
							fillRule="evenodd"
							d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
						/>
					</svg>
					<span>Back to all events</span>
				</button>
			</Col>
		</Row>
	);
}

function EventComponent({ eventId }: { eventId: string }) {
	const { eventData, loading, goBack } = useEventViewModel({ eventId });
	const { t } = useTranslation();

	if (loading) {
		return (
			<Container style={{ minHeight: '65vh' }}>
				<Helmet>
					<title>{`${t('club_name')} - Event not found`}</title>
				</Helmet>
				<BackButton goBack={goBack} />
				<Row className="justify-content-center align-items-center" style={{ minHeight: '65vh' }}>
					<Col md={10} lg={6}>
						<Row>
							<Col className="text-center">Loading event...</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	if (!eventData) {
		return (
			<Container>
				<Helmet>
					<title>{`${t('club_name')} - Event not found`}</title>
				</Helmet>
				<BackButton goBack={goBack} />
				<Row className="justify-content-center align-items-center" style={{ minHeight: '65vh' }}>
					<Col md={10} lg={6}>
						<Row>
							<Col className="text-center">Event not found</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<Container>
			<Helmet>
				<title>{`${t('club_name')} - ${eventData.name}`}</title>
			</Helmet>
			<BackButton goBack={goBack} />
			<Row className="justify-content-center">
				<Col md={10} lg={6}>
					<Row className="my-4">
						<Col className="text-center">
							<img className="eventSplash" src={eventData.imageUri} alt="event splash" />
						</Col>
					</Row>
					<Row className="my-4">
						<Col className="text-center">
							<div className="eventTagsContainer">
								{eventData.tags.map((tag) => (
									<TagPill key={tag} tag={tag} />
								))}
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="eventTitle">{eventData.name}</Col>
					</Row>
					<Row>
						<Col>{dateformat(eventData.startTimeMs, 'ddd, mmm d, h:MM TT Z')}</Col>
					</Row>
					<Row className="my-4">
						<Col>
							<Row>
								<Col className="eventLabel">Event Details</Col>
							</Row>
							<Row>
								<Col className="eventLabelDescription">{eventData.description}</Col>
							</Row>
						</Col>
					</Row>

					<Row className="my-5">
						<Col sm={6} xs={8} className="mx-auto">
							<a
								className="eventPillButtonLink"
								href={`${t('signup_url')}${window.location.search}`}
							>
								<div className="eventPillButton">Apply now</div>
							</a>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
}

export default EventComponent;
