import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useEventData } from './model';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dateformat from 'dateformat';
import { EventData } from '../../api/events';

function _displayAgeRange({ minAge, maxAge }: EventData) {
	return minAge && maxAge && minAge !== 18 && maxAge !== 100;
}

const HomePage: React.FC = () => {
	const { t } = useTranslation();
	const { events, loading } = useEventData();

	const noEvents = events.length === 0 && !loading;

	return (
		<Container className="homepage-container mt-5" style={{ minHeight: '65vh' }}>
			<h1>EVENTS</h1>
			{!loading && (
				<p className="mb-5">
					{noEvents
						? 'Check back soon for some events near you...'
						: `Take a peek at ${t('club_name')}'s social calendar.`}
				</p>
			)}
			{loading ? (
				<></>
			) : (
				<Row xs={1} sm={2} lg={3} className="g-4">
					{events.map((event) => (
						<Col key={event.id}>
							<Link to={`/events/${event.id}`} className="event-link">
								<Card className="event-card">
									<Card.Img
										variant="top"
										src={event.imageUri}
										alt={event.name}
										className="event-image"
									/>
									<Card.Body className="text-start">
										<Card.Subtitle className="event-meta my-2 text-start">
											{event.locationName && `${event.locationName}`}
										</Card.Subtitle>
										<Card.Subtitle className="event-meta mb-4 text-start">
											{dateformat(event.startTimeMs, 'ddd, mmm d, h:MM TT Z')}
										</Card.Subtitle>

										<Card.Title className="event-title text-start">{event.name}</Card.Title>
										<Card.Text className="event-description text-start">
											{event.description}
										</Card.Text>
										{_displayAgeRange(event) && (
											<Card.Text className="event-ages text-start">
												<strong>Ages:</strong> {event.minAge} — {event.maxAge}
											</Card.Text>
										)}
									</Card.Body>
								</Card>
							</Link>
						</Col>
					))}
				</Row>
			)}
		</Container>
	);
};

export default HomePage;
